input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.order-detail-history > ul.timeline {
  list-style-type: none;
  position: relative;
}

.order-detail-history > ul.timeline:before {
  content: ' ';
  background: #bfc4c9;
  display: inline-block;
  position: absolute;
  left: 23px;
  width: 2px;
  height: 100%;
}

.order-detail-history > ul.timeline > li {
  margin: 20px 0;
}

.order-detail-history > ul.timeline > li:before {
  content: ' ';
  background-color: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #bfc4c9;
  left: 16px;
  width: 16px;
  height: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-action-button {
  display: flex;
  flex-direction: row;
}

.add-product-image {
  height: 70%;
}

@media screen and (max-width: 500px) {
  .flex-action-button {
    gap: 10px;
    flex-direction: column;
  }

  .flex-action-button > *,
  .flex-action-button > * > * {
    width: 100%;
  }

  .add-product-image {
    width: 80%;
    height: auto;
  }
}